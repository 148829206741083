import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material';
import { ChevronLeft as BackIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
  Link as RouterLink,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useState } from 'react';
import { Routes } from './Routes';
import { VersionNumber } from './VersionNumber';

export const AppInner: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const customPrint = useRouteMatch('/custom-print') != null;
  const history = useHistory();
  return (
    <>
      <AppBar
        position="fixed"
        style={{ zIndex: 100, backgroundColor: '#375954' }}
      >
        <Toolbar>
          {!drawerOpen && (
            <IconButton
              edge="start"
              onClick={() => setDrawerOpen(true)}
              style={{ marginRight: '1em', color: '#fff' }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          )}
          <RouterLink style={{ flex: '1 0', textDecoration: 'none' }} to="/">
            <Typography variant="h6" noWrap style={{ color: 'white' }}>
              AutoPrint
            </Typography>
          </RouterLink>
          {customPrint ? (
            <Button
              onClick={() => history.goBack()}
              style={{ color: '#fff', marginRight: '5em' }}
            >
              <BackIcon style={{ marginRight: '0.3em', marginBottom: 3 }} />
              Back
            </Button>
          ) : (
            <Button
              component={RouterLink}
              to="/custom-print"
              style={{ color: '#fff', marginRight: '4em' }}
            >
              Custom Print
            </Button>
          )}
          <VersionNumber />
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 64 }}>
        <Routes drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      </div>
    </>
  );
};
